@import "@/scss/mixin/screen-size.scss";
@import "@/scss/mixin/layout.scss";
@import "@/scss/default.scss";

$block-max-width: 410px;
$block-min-height_lg: 186px;
$block-min-height_md: 140px;
$header-height: 64px;
$total-rating-size: 70px;
$verification-icon-size: 45px;
$verification-icon-size_sm: 20px;
$verification-icon-size_lg: 100px;

.my-info {
  width: 100%;
  margin: 0 auto;
  max-width: $max-page-content-width;
  @include flex-layout-vertical();

  @include for-xxlarge-screen() {
    @include flex-layout-horizontal($align: flex-start, $gap: $gap-em);
  }

  /* обертка для группы элементов с border **/
  &__group {
    @include flex-layout-vertical($justify: flex-start);
    width: 100%;
    padding: $gap-em;
    border: 1px solid $color-lightgrey;
    align-items: stretch;
    border-radius: $gap-em;
    max-width: 720px;

    @include for-xlarge-screen() {
      width: auto;
    }

    h3 {
      white-space: initial;
      text-align: center;
      color: $color-black;
      font-size: $fz-text;
      font-weight: $fw-800;
    }

    &-divider {
      margin: 0;
    }
  }

  /* Раздел: форма водителя **/
  &-form {
    flex: 1;
    width: 100%;
    @include flex-layout-vertical($align: normal);

    @include for-xlarge-screen() {
      @include flex-layout-horizontal(
        $justify: flex-start,
        $align: flex-start,
        $gap: $gap-em
      );
    }

    /* колонка 1 (аватар), 2 (форма) **/
    &__column {
      @include flex-layout-vertical();
      &_wide {
        width: 100%;
        align-items: center;
        @include for-xlarge-screen() {
          @include flex-layout-vertical(normal, flex-start);
        }
      }
    }

    /** Персональная информация + рейтинг */
    &__row {
      width: 100%;
      @include flex-layout-vertical();
      flex-direction: column;

      @include for-xlarge-screen() {
        @include flex-layout-horizontal(
          $align: flex-start,
          $justify: flex-start,
          $gap: $gap-em
        );
      }
    }

    /* Подраздел формы: Рейтинг **/
    &__rating {
      &-group {
        width: 100%;
        @include flex-layout-horizontal(
          $align: center,
          $justify: space-between
        );
      }
    }

    &__categories-list,
    &__reviews-list {
      @include flex-layout-horizontal($justify: flex-start);
      list-style: none;
      margin: 0;
      padding: 0;
    }

    /* Подраздел формы: Категории прав **/
    &__categories {
      &-list {
        flex-wrap: wrap;
      }
      @include flex-layout-vertical($align: normal);
      p {
        margin: 0;
      }
    }

    &__buttons {
      @include flex-layout-horizontal(flex-start);
    }

    /** два инпута в ряд */
    &__inputs-group {
      display: grid;
      gap: $gap-em;
      grid-template-columns: 1fr;

      @include for-xlarge-screen() {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &__organization-fields {
      width: 100%;
      @include flex-layout-vertical($align: center);
      
      @include for-xlarge-screen() {
        width: auto;
        @include flex-layout-vertical($align: flex-start);
      }
    }
  }

  &__tabs {
    width: 100%;
    max-width: $max-page-content-width;
    margin: 0 auto;
  }

  /** Раздел "Пользователи" */
  &-users {
    @include flex-layout-vertical(flex-start, center, calc(2 * #{$gap-em}));

    &__list {
      @include flex-layout-horizontal($align: flex-start);
      flex-wrap: wrap;
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
  }

  /** Раздел "Подтверждение номера телефона" */
  &__verification {
    @include flex-layout-horizontal(space-between);

    &-form {
      &-divider {
        margin: $gap-em 0;
      }

      &-subtitle {
        margin: 0;
      }

      &-btn {
        margin-top: calc(2 * #{$gap-em});
      }
    }
  }

  /** Раздел "Профиль не заполнен" */
  &__profile-completeness {
    @include flex-layout-horizontal(space-between);

    &-titles {
      p {
        margin: 0;
      }
    }
        
    &-title {
      margin: 0;
      font-weight: 700;
    }

    &-list {
      padding-left: calc(2 * #{$gap-em});
      margin: 0;
      li {
        &:not(:last-of-type) {
          margin-bottom: $gap-primary;
        }
      }
    }
  }

  &__profile-completeness,
  &__verification {
    svg {
      width: $verification-icon-size_sm;
      height: $verification-icon-size_sm;
      fill: $color-dark-green;
    }
    .error {
      svg {
        fill: $color-accent;
      }
    }
  }

  &__driver-alert {
    max-width: 400px;
  }

  /** Раздел "Анкета" */

  &__questionnaire {
    @include flex-layout-vertical();

    @include for-medium-screen() {
      @include flex-layout-horizontal($align: flex-start);
    }

    .my-info__group {
      max-width: 450px;
      width: 100%;
    }
  }
}

.my-rating {
  overflow: auto;
  height: 100%;
  @include flex-layout-vertical(space-between, normal);

  &__content {
    @include flex-layout-horizontal();

    @media screen and (max-height: 1000px) {
      @include flex-layout-horizontal($align: flex-start);
    }

    flex: 1;
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }

  /** Список с блоками */
  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: $gap-em;
    list-style-type: none;

    @include for-large-screen() {
      grid-template-columns: repeat(3, 1fr);
      gap: calc(2 * $gap-em);
    }

    @include for-small-screen() {
      gap: $gap-primary;
    }
  }

  /** Отдельный блок */
  &__block {
    @include flex-layout-vertical();
    position: relative;
    max-width: $block-max-width;
    min-height: $block-min-height_md;
    padding: 0 $gap-em;
    border: 3px solid $color-main;
    border-radius: calc(2 * $gap-em);
    text-align: center;
    transition: 0.2s background-color;

    &-title {
      margin: 0;
      font-size: $fz-title_6;
      transition: 0.2s opacity;
      @include for-small-screen() {
        font-size: $fz-primary;
      }
    }

    &-link {
      position: absolute;
      inset: 0;
    }

    &:hover {
      background-color: $color-lighter-grey;
      .my-rating__block-title {
        opacity: 0.7;
      }
    }

    @include for-large-screen() {
      padding: 0 calc(2 * $gap-em);
      min-height: $block-min-height_lg;
      &-title {
        font-size: $fz-big;
      }
    }

    /** Центральный блок с общим рейтингом */
    &.total {
      grid-column: 1/3;
      grid-row: 1/2;
      max-width: 100%;

      @include for-large-screen() {
        grid-column: auto;
        grid-row: auto;
        max-width: $block-max-width;
      }

      .my-rating__block-title {
        max-width: 246px;
        font-size: $fz-title_4;
        @include for-small-screen() {
          max-width: auto;
          font-size: $fz-text;
        }
      }
    }

    /** рейтинг в углу карточки для каждого блока */
    &-rating {
      @include flex-layout-horizontal();
      position: absolute;
      top: $gap-em;
      right: $gap-em;
      border-radius: $gap-primary;
      font-weight: $fw-700;
      color: $color-white;
    }
  }
}
