@import "@/scss/mixin/screen-size.scss";
@import "@/scss/mixin/layout.scss";
@import "@/scss/default.scss";

.sider-banner-title {
  margin: 0;
  font-size: $fz-title_6;
  color: $color-darker-grey;
  font-weight: $fw-800 !important;
}
