@import "@/scss/mixin/screen-size.scss";
@import "@/scss/mixin/layout.scss";
@import "@/scss/mixin/ellipsis.scss";
@import "@/scss/default.scss";

/** компонент строки с заголовком и значением, напр. "Год выпуска: 2011" */
.detail-description {
  margin: 0;
  @include flex-layout-vertical(flex-start, flex-start, 0);
  &.vertical {
    @include flex-layout-vertical(flex-start, flex-start);
  }

  @include for-medium-screen() {
    @include flex-layout-horizontal(flex-start, flex-start);
  }

  &__caption,
  &__value {
    font-weight: $fw-500;
    @include for-medium-screen() {
      font-size: $fz-title_6;
    }
  }

  &.small {
    .detail-description__caption,
    .detail-description__value {
      font-size: $fz-primary;
    }
  }
  
  &__caption {
    min-width: max-content;
    color: $color-darkgrey;
  }
  &__value {
    white-space: pre-line;
  }
}
