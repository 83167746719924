@import "@/scss/mixin/screen-size.scss";
@import "@/scss/mixin/layout.scss";
@import "@/scss/default.scss";
@import "@/scss/hide.scss";

.projects {
  width: 100%;
  height: 100%;
  @include flex-layout-vertical($align: center, $justify: flex-start);

  &__main {
    width: 100%;
    max-width: $max-section-width;
    padding: $gap-em;
    border: 1px solid $color-lightgrey;
    border-radius: 0.5em;
    box-shadow: $section-box-shadow;
  }

  &__header {
    padding: calc(2 * #{$gap-em}) $gap-em;
    border-radius: 4px;
    background-color: $color-lighter-grey;

    .projects__title {
      font-size: $fz-title_3;
      font-weight: $fw-900;
      @include for-medium-screen() {
        font-size: $fz-title_1;
      }
    }
  }

  &__content {
    padding: 0;
    @include flex-layout-vertical(flex-start, normal, $gap-em);

    @include for-medium-screen() {
      @include flex-layout-vertical(flex-start, normal, calc(2 * #{$gap-em}));
    }
  }
}
