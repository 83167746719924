@import "@/scss/default.scss";
@import "@/scss/hide.scss";
@import "@/scss/mixin/form.scss";
@import "@/scss/mixin/screen-size.scss";
@import "@/scss/mixin/card/list.scss";

.vacancies {
  &__list {
    @include card-list-vertical();
  }
}
