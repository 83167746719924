@import "@/scss/default.scss";
@import "@/scss/mixin/screen-size.scss";
@import "@/scss/mixin/layout.scss";

$form-width: 21%;

@mixin form-white($max-width: $form-width) {
  min-width: 320px;
  width: fit-content;
  max-width: $max-width;
  height: fit-content;
  padding: 40px 24px;
  background: $color-white;
  border-radius: 12px;
  box-shadow: 0 24px 48px $color-grey;

  @include flex-vertical();

  @include for-small-screen() {
    padding: 24px;

    h1 {
      font-size: $fz-big;
    }

    .ils-input {
      padding: 8px;
    }

    .ant-select {
      height: 45px;
    }
  }
}