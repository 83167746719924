@import "@/scss/mixin/screen-size.scss";
@import "@/scss/mixin/layout.scss";
@import "@/scss/default.scss";

$column-width: 270px;
$card-size: 90px;
$card-size_sm: 80px;
$icon-width_lg: 45px;
$icon-height_lg: 35px;
$icon-size_sm: 30px;
$card-box-shadow: 0px 0px 10px -6px $color-darker-grey;

.resources {
  /** Логистические сервисы */
  .logistic-services {
    width: 100%;
    flex: 1;
    margin: 0 auto;
    @include flex-layout-vertical(
      $align: normal,
      $justify: flex-start,
      $gap: $gap-em
    );

    /** список групп сервисов */
    &__list {
      flex: 1;
      width: 100%;
      max-width: calc(3 * #{$column-width});
      margin: 0 auto;
      padding: 0 $page-p-md-x;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax($column-width, 1fr));
      gap: $gap-em;

      @include for-xlarge-screen() {
        padding: 0 $page-p-lg-x;
        max-width: $max-page-content-width;
      }
    }

    /** группа сервисов */
    &__group {
      @include flex-layout-vertical(
        $justify: flex-start,
        $gap: calc(2 * #{$gap-em})
      );
      max-height: 70vh;
      padding: $gap-em;
      border-radius: $gap-primary;
      background-color: $color-white;
      box-shadow: $card-box-shadow;

      @include for-small-screen() {
        gap: $gap-em;
      }
    }

    &__group-title,
    &__column {
      width: 100%;
    }

    /* заголовок группы */
    &__group-title {
      min-height: 62px;
      text-align: center;
      border-bottom: 1px solid $color-grey;

      p {
        margin: 0;
        text-transform: uppercase;
        font-size: $fz-title_5;
        line-height: 1.2;
        font-weight: $fw-800;
        color: $color-darker-grey;
      }

      p.subtitle {
        padding-top: $gap-primary;
        font-size: $fz-primary;
      }

      @include for-small-screen() {
        min-height: 40px;
        padding-bottom: $gap-primary;
        p {
          font-size: $fz-title_6;
        }
        p.subtitle {
          padding-top: $gap-primary;
          font-size: $fz-small;
        }
      }
    }

    /* вертикальная колонка с карточками */
    &__column {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      justify-items: center;
      justify-content: center;
      gap: calc(2 * #{$gap-em}) 0;
      margin: 0;
      padding: 0;

      li {
        list-style-type: none;
      }

      @include for-small-screen() {
        width: 100%;
        gap: $gap-em $gap-primary;
        grid-template-columns: repeat(3, minmax($icon-width_lg, 1fr));
      }
    }

    /* карточка */
    &__card {
      @include flex-layout-vertical();

      p {
        margin: 0 !important;
        font-size: $fz-primary;
        font-weight: $fw-600;
        line-height: 1.1;
        color: $color-darker-grey;
        text-align: center;
        word-break: keep-all;
        @include for-small-screen() {
          font-size: $fz-small;
        }
      }

      /* контейнер иконки */
      &-icon {
        @include flex-layout-horizontal();
        position: relative;
        width: $card-size;
        height: $card-size;
        border: 3px solid $color-darkgrey;
        background-color: $color-white;
        border-radius: calc(#{$card-size_sm} * 0.3);
        transition: 0.2s all;

        svg {
          width: $icon-width_lg;
          height: $icon-height_lg;
          fill: $icon-height_lg;

          path,
          circle {
            fill: $color-darkgrey;
          }
        }

        @include for-small-screen() {
          width: $card-size_sm;
          height: $card-size_sm;
          svg {
            width: $icon-size_sm;
            height: $icon-size_sm;
          }
        }
      }

      &.active {
        .logistic-services__card-icon {
          border-color: $color-green;
        }
      }

      &.fast-delivery {
        .logistic-services__card-icon {
          background-color: $color-blue-transparent;
        }
      }

      &:hover {
        .logistic-services__card-icon {
          background-color: $color-green;
          box-shadow: $card-box-shadow;

          svg,
          path,
          circle {
            fill: $color-white;
          }
        }
      }

      &:not(.active) {
        pointer-events: none;
      }
    }
  }
}
