@import "@/scss/mixin/screen-size.scss";
@import "@/scss/mixin/layout.scss";
@import "@/scss/default.scss";

.logistic-services__card-badge {
  position: absolute;

  &_traffic-light {
    top: -7px;
    right: -12px;
    img {
      height: 55px;
    }
  }

  &_login {
    top: -6px;
    right: 0;
    .ant-ribbon {
      line-height: 1.3;
    }
    span {
      font-size: $fz-small;
    }
    @include for-small-screen() {
      top: -12px;
      span {
        font-size: $fz-xs;
      }
    }
  }

  &_lock {
    @include flex-layout-horizontal();
    top: -10px;
    right: -10px;
    svg,
    path {
      fill: $color-darkgrey !important;
      width: 20px !important;
      height: 20px !important;
    }
    border-radius: 50%;
    background: $color-white;
    padding: 4px;
    box-shadow: 0px 0px 10px -6px #2a3d4f;
  }
}
