@import "@/scss/default.scss";
@import "@/scss/mixin/layout.scss";

.ils-file-card {
  @include flex-layout-horizontal(space-between);
  border: 1px solid $color-lightgrey;
  padding: $gap-primary $gap-em;
  border-radius: 4px;
  width: 100%;
  &.fixed-width {
    max-width: 330px;
  }

  div {
    @include flex-layout-horizontal();
  }
  p {
    margin: 0;
  }
}
