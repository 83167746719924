@import "@/scss/default.scss";
@import "@/scss/mixin/form.scss";
@import "@/scss/mixin/layout.scss";
@import "@/scss/mixin/screen-size.scss";

.popover-content {
  max-height: 40vh;
  min-height: 30vh;
  overflow-y: auto;
  @include flex-layout-vertical(flex-start);
}
