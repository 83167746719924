@import "@/scss/default.scss";
@import "@/scss/mixin/screen-size.scss";
@import "@/scss/mixin/layout.scss";
@import "@/scss/mixin/sidebar-filters.scss";
@import "@/scss/mixin/card/list.scss";

$card-image-width: 225px;

.admin-questionnaire {
  width: 100%;
  height: max-content;

  &__content {
    @include flex-layout-horizontal(flex-start, flex-start, $gap-em);
    position: relative;
  }

  &__sidebar {
    @include sidebar-filters();
    gap: 0 !important;
  }

  &__upload-form {
    &-title {
      margin-bottom: $gap-em !important;
    }
  }

  &__status {
    .detail-description__caption {
      min-width: min-content;
    }
    p {
      margin: 0;
    }
  }

  &__list {
    width: 100%;
    @include card-list-vertical();
  }

  &__card {
    width: 100%;
    padding: 1em;
    border: 1px solid $color-lightgrey;
    position: relative;
    @include flex-layout-horizontal(space-between, flex-start);
    p {
      margin: 0;
    }
    &-avatar {
      img {
        width: $card-image-width;
      }
    }

    &-info {
      flex: 1;
      @include flex-vertical();
    }

    &-divider {
      margin: 0.5em 0;
    }

    &-ribbon {
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .ant-spin-nested-loading {
    width: 100%;
  }
}
