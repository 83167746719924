@import "@/scss/mixin/screen-size.scss";
@import "@/scss/mixin/layout.scss";
@import "@/scss/default.scss";


.form-avatar {
  width: 330px;
  aspect-ratio: 4 / 3;
  height: auto;

  > div,
  .ant-avatar,
  span.ant-upload-picture-card-wrapper,
  .ant-upload-list-picture-card-container,
  .ant-upload,
  .ant-upload-list,
  .ant-upload-list-item-container {
    width: 100% !important;
    height: 100% !important;
  }
}

