@import "@/scss/mixin/screen-size.scss";
@import "@/scss/mixin/layout.scss";
@import "@/scss/default.scss";
@import "@/scss/mixin/ellipsis.scss";

$blocks-lg-gap: calc(2 * #{$gap-em});
$blocks-md-gap: $gap-em;
$contest-card-content-height: 150px;
$contest-card-width: 230px;

.start-page {
  height: 100%;
  overflow: auto;
  @include flex-layout-vertical($align: normal, $justify: space-between);
  background-color: $bg-color-footer;

  &__content {
    width: 100%;
    max-width: $max-page-content-width;
    padding: 0;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      "main"
      "left-sider"
      "right-sider";
    gap: $blocks-md-gap;
    align-items: flex-start;

    @include for-xlarge-screen() {
      gap: $blocks-lg-gap;
      padding: $page-p-lg-y $page-p-lg-x;
      grid-template-areas: "left-sider main right-sider";
      grid-template-columns: minmax(300px, 0.4fr) 1fr minmax(300px, 0.4fr);
    }
  }

  &__main {
    grid-area: main;
    overflow: hidden;
    padding-top: $gap-em;
  }

  .right-sider {
    grid-area: right-sider;
  }

  .left-sider {
    grid-area: left-sider;
    overflow: hidden;
    @include flex-layout-vertical(
      $align: normal,
      $justify: flex-start,
      $gap: $blocks-lg-gap
    );
  }

  .left-sider,
  .right-sider {
    padding: $page-p-md-y $page-p-md-x;
  }

  &__footer {
    background-color: $color-white;
    @include flex-layout-horizontal(flex-start);

    &-title {
      color: $color-darker-grey !important;
      font-weight: $fw-800 !important;
    }

    &-content {
      width: 100%;
      max-width: $max-page-content-width;
      padding: $gap-em $gap-em $gap-em $blocks-lg-gap;
      @include flex-layout-horizontal(flex-start, center, $blocks-lg-gap);
    }

    &-list {
      @include flex-layout-horizontal(flex-start, center, $gap-em);
      padding: 0;
      margin: 0;
    }

    &-list-item {
      list-style: none;
    }

    svg {
      fill: $color-darker-grey;
      transition: 0.2s ease-in-out;
      &:hover {
        opacity: 0.8;
      }
    }

    &-divider {
      margin: 0;
      height: auto;
      align-self: stretch;

      &_mobile {
        margin: $gap-em 0;
      }
    }

    &_mobile {
      @include flex-layout-vertical(flex-start, flex-start, 0);
    }
  }

  &__main,
  .right-sider {
    @include flex-layout-vertical(
            $align: normal,
            $justify: flex-start,
            $gap: $blocks-md-gap
    );
  }

  @include for-xlarge-screen() {
    &__main,
    .right-sider {
      @include flex-layout-vertical(
              $align: normal,
              $justify: flex-start,
              $gap: $blocks-lg-gap
      );
      padding: 0;
      overflow-x: unset;
    }
    .left-sider {
      padding: 0;
    }
  }

  &__contests {
    &-header {
      margin-left: $gap-em !important;

      @include for-xlarge-screen() {
        margin-left: 0 !important;
        margin-bottom: $gap-em !important;
      }
    }

    &-list {
      @include flex-layout-horizontal($justify: flex-start, $gap: $gap-em);
      margin: 0;
      padding: $gap-em;
      overflow-x: auto;
      list-style-type: none;

      @include for-xlarge-screen() {
        padding: 0;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax($contest-card-width, auto));
      }
    }

    &-card {
      width: 100%;
      min-width: $contest-card-width;

      .ant-card {
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;
      }

      &-title {
        @include no-overflow-ellipsis();
        line-height: 1.3;
        font-weight: $fw-800 !important;
      }

      &-content {
        min-height: $contest-card-content-height;
        @include flex-layout-vertical($align: normal, $justify: space-between);
      }

      &-btn {
        margin-top: $blocks-lg-gap;
      }
    }

    &-modal {
      &-content {
        max-height: 60vh;
        overflow-y: auto;
      }

      p {
        margin: 0;
      }

      &-title {
        font-size: $fz-title_5 !important;
        font-weight: $fw-800 !important;
        @include for-medium-screen() {
          font-size: $fz-title_2 !important;
        }
      }
    }
  }

  &__vacancies {
    gap: $blocks-lg-gap;
    padding: 0 18px;

    &-button {
      width: 100%;
      button {
        width: 100%;
      }

      @include for-medium-screen() {
        width: auto;
      }
    }

    @include for-xlarge-screen() {
      padding: 0;
    }
  }
}
