@import "@/scss/mixin/screen-size.scss";
@import "@/scss/mixin/layout.scss";
@import "@/scss/default.scss";

.news-list {
  @include flex-layout-horizontal($justify: flex-start, $gap: $gap-em);
  margin: 0;
  padding: $gap-em;
  overflow-x: auto;
  list-style-type: none;

  @include for-xlarge-screen() {
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(230px, auto));
  }
}
