@import "@/scss/mixin/screen-size.scss";
@import "@/scss/mixin/ellipsis.scss";
@import "@/scss/mixin/layout.scss";
@import "@/scss/default.scss";

$card-width: 230px;
$dot-size: 8px;
$content-height: 220px;

.news-card {
  width: 100%;
  min-width: $card-width;
  background-color: $color-white;
  border-radius: calc(4 * #{$gap-em});

  &__cover {
    position: relative;
    img {
      object-fit: cover;
    }
  }

  /** статус Активная / архивная */
  &__status {
    @include flex-layout-horizontal();
    position: absolute;
    bottom: $gap-primary;
    right: $gap-primary;
    padding: 2px $gap-em;
    border-radius: $gap-em;
    background-color: $color-black-transparent;

    p {
      margin: 0;
      color: $color-white;
      font-weight: $fw-600;
    }

    &-dot {
      height: $dot-size;
      width: $dot-size;
      border-radius: 50%;
      background-color: $color-main;
    }
  }


  &__title {
    line-height: 1.3;
    font-weight: $fw-800 !important;
    @include no-overflow-ellipsis();
  }

  &__content {
    min-height: $content-height;
    @include flex-layout-vertical($align: normal, $justify: space-between);
  }

  &__type {
    width: max-content;
    margin-bottom: $gap-em;
    padding: 4px $gap-em;
    border-radius: $gap-em;
    background-color: $color-main-light;

    p {
      margin: 0;
      color: $color-main;
      font-size: $fz-small;
      font-weight: $fw-600;
    }
  }

  &__footer {
    color: $color-grey;
    width: 100%;
    @include flex-layout-horizontal($justify: space-between);
  }

  &__group {
    @include flex-layout-horizontal();
  }

  &__likes {
    margin-bottom: $gap-primary;
    color: $color-darkgrey;
    font-weight: $fw-600;
    @include flex-layout-horizontal($gap: 0);

    &.active {
      svg {
        fill: $color-main;
      }
    }
  }
}
