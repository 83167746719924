@import "@/scss/mixin/screen-size.scss";
@import "@/scss/mixin/layout.scss";
@import "@/scss/default.scss";

.sider-banner {
  flex: 1;
  padding: $gap-em;
  min-width: 328px;
  border-radius: $gap-primary;
  background-color: $color-white;

  &_empty {
    height: 100px;
    @include flex-layout-vertical();
    color: $color-darker-grey;
  }

  &:has(.sider-banner_empty) {
    display: none;
    @include for-xlarge-screen() {
      display: block;
    }
  }

  &_footer-mobile {
    margin: $gap-em;
    display: block;
    
    @include for-medium-screen() {
      display: none;
    }
  }

  @include for-xlarge-screen() {
    min-width: 100%;
  }
}
