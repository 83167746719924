@import "@/scss/mixin/screen-size.scss";
@import "@/scss/mixin/layout.scss";
@import "@/scss/mixin/ellipsis.scss";
@import "@/scss/default.scss";
@import "@/scss/mixin/detailed-info/detailed-info.scss";

.organization {
  @include detailed-info();
  
  /** header */
  &__header {
    /** Основной заголовок */
    .organization__title {
      @include detailed-info-main-title();
    }

    /** Партнер Водителей России */
    .organization__partnership {
      @include detailed-info-membership();
    }
  }

  /** основной блок */
  &__content {
    @include detailed-info-content();
  }

  /** подраздел */
  &__section {
    @include detailed-info-section();
  }

  /** боковой раздел */
  &__sidebar {
    @include detailed-info-sidebar();

    &-title {
      font-size: $fz-title_5 !important;
      font-weight: $fw-800 !important;
      margin-bottom: $gap-em !important;
      text-align: center;

      @include for-medium-screen() {
        font-size: $fz-title_3 !important;
      }
    }
  }

  /** аватар */
  &__avatar {
    @include detailed-info-avatar();
    }
    
  /** header + аватар */
  &__row {
    @include flex-layout-vertical($align: flex-start);
    
    @include for-medium-screen() {
      @include flex-layout-horizontal(space-between, flex-start);
    }
  }

  &__back-link {
    display: flex;
    gap: $gap-primary;
    font-size: $fz-text;
    font-weight: $fw-600;
    margin-bottom: $gap-primary;
  }
}
