@import "@/scss/fonts.scss";
@import "@/scss/default.scss";
@import "@/scss/hide.scss";
@import "@/scss/mixin/layout.scss";
@import "@/scss/mixin/screen-size.scss";
@import "react-quill/dist/quill.snow.css";

html {
  box-sizing: border-box;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  margin: 0;
}

.ils-wrapper {
  overflow: hidden;
  height: 100vh;
  background-color: $color-white;

  header {
    width: 100%;
    padding: 0 10px;
    @include flex-justify-align(space-between, center, $gap-em);

    background-image: linear-gradient(-35deg, $color-main 50%, $color-white 50%);

    @include for-xxlarge-screen() {
      background-image: linear-gradient(-35deg, $color-main 77%, $color-white 77%);
      padding: 0 40px;
    }
  }

  main {
    @include flex-vertical(0);
  }

  footer {
    background-color: $color-white;
  }
}
