@import "@/scss/mixin/screen-size.scss";
@import "@/scss/mixin/layout.scss";
@import "@/scss/default.scss";

/* Подраздел формы: опыт вождения **/
.driver-experience {
  @include flex-layout-vertical($align: normal);
  width: 100%;
  align-items: stretch;
  max-width: 720px;

  @include for-xlarge-screen() {
    width: auto;
  }

  p {
    margin: 0;
  }
  &-divider {
    margin: 0;
  }

  &-list {
    @include flex-layout-vertical($justify: flex-start);
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &-header {
    display: none;
    @include for-xlarge-screen() {
      display: grid;
      grid-template-columns: 2fr 1fr 2fr;
    }
  }

  /** Один элемент подраздела опыт + кнопка удаления этого элемента */
  &-item {
    width: 100%;
    @include flex-layout-horizontal();
    padding-bottom: $gap-em;
    border-bottom: 1px solid $color-lightgrey;

    &:last-of-type {
      padding-bottom: 0;
      border-bottom: none;
    }

    @include for-xlarge-screen() {
      padding-bottom: 0;
      border-bottom: none;
    }

    /** Поля формы опыт */
    .driver-experience-fields {
      width: 100%;
      display: grid;
      grid-template-columns: auto;
      grid-template-columns: repeat(1, auto);
      gap: 0 $gap-primary;

      @include for-xlarge-screen() {
        grid-template-columns: 2fr 1fr 2fr;

        .ant-form-item-label {
          display: none;
        }
      }
    }
  }

  /* Список + кнопка добавления **/
  &-column {
    @include flex-layout-vertical($align: normal);

    @include for-xlarge-screen() {
      @include flex-layout-vertical($align: flex-start);
    }

    .driver-experience-list {
      @include flex-layout-vertical();
    }
  }
}
