@import "@/scss/mixin/layout.scss";
@import "@/scss/default.scss";

.vacancies-sort {
  width: 300px;
  min-height: auto;
  border-radius: 10px;

  &__menu {
    .ant-menu-item {
      height: auto;
      gap: calc(2 * #{$gap-em});
      @include flex-layout-horizontal(center, center);

      svg {
        vertical-align: middle;
        stroke: $color-white;
      }

      &.ant-menu-item-selected {
        svg {
          stroke: $color-black;
        }
      }
    }
  }
}