@import "@/scss/default.scss";

.useful-links {
  &__divider {
    margin: 1em 0;
  }
  &__list {
    display: grid;
    justify-items: center;
    grid-template-columns: repeat( auto-fit, minmax(120px, 1fr) );
    gap: $gap-em;
    margin: 0;
    padding: 0;
    list-style: none;
  }
}
