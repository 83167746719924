@import "@/scss/default.scss";
@import "../layout";

@mixin card-list-vertical() {
  @include flex-vertical();

  margin: 0;
  padding: 0;
  width: 100%;

  li {
    list-style: none;
  }
}