@import "@/scss/mixin/screen-size.scss";
@import "@/scss/default.scss";

$alert-icon-size: 20px;

.driver-apply-alert-modal {
  p {
    margin: 0;
  }
    
  &__subtitle {
    font-weight: 600;
  }

  &__group {
    display: flex;
    align-items: flex-start;
    gap: $gap-primary;

    &_phone {
      margin-bottom: $gap-em;
    }
  }

  &__list {
    margin: 0;
    padding-left: $gap-em;
    padding-top: $gap-primary;
    li {
      &:not(:last-of-type) {
        margin-bottom: $gap-primary;
      }
    }
  }

  &__divider {
    margin: $gap-em 0;
    background-color: $color-darker-grey;
  }

  svg {
    fill: $color-red-main;
    width: $alert-icon-size;
    height: $alert-icon-size;
  }
}