@import "@/scss/default.scss";

.apply-status-title {
  font-weight: 500;
  color: $color-darker-grey;

  &.confirm {
    color: $color-green;
  }
  &.refuse {
    color: $color-main-medium;
  }
}
