@import "@/scss/default.scss";
@import "@/scss/mixin/form.scss";
@import "@/scss/mixin/screen-size.scss";

@mixin sidebar-filters() {
  min-width: 270px;
  @include for-large-screen() {
    @include form-white();
    padding: 24px;
    border: 1px solid $color-lightgrey;
    box-shadow: none;
    min-height: 50vh;
    height: fit-content;
    max-height: fit-content;
  }
}
