@import "@/scss/mixin/screen-size.scss";
@import "@/scss/mixin/layout.scss";
@import "@/scss/default.scss";

.data-processing-rules {
  height: 100%;
  @include flex-layout-vertical($align: normal, $justify: space-between);

  &__content {
    width: 100%;
    height: 100%;
    max-width: $max-page-content-width;
    margin: 0 auto;
    @include flex-layout-vertical($align: normal, $justify: space-between);
  }

  &__header {
    width: 100%;
    position: relative;
    padding: 2em;
    text-align: center;

    h2 {
      max-width: 460px;
      margin: 0 auto;
      white-space: normal;
      @include for-small-screen() {
        font-size: $fz-title_5;
        max-width: 320px;
      }
      @include for-medium-screen() {
        max-width: 100%;
      }
    }
  }

  &__text {
    white-space: break-spaces;
    overflow-y: scroll;
    height: 100%;
    padding: $gap-primary $gap-em;
  }

  &__go-back {
    position: absolute;
    top: 50%;
    left: $gap-em;
    transform: translate(0, -50%);
    @include for-small-screen() {
      left: $gap-primary;
    }
  }
}
