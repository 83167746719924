@import "@/scss/mixin/screen-size.scss";
@import "@/scss/mixin/layout.scss";
@import "@/scss/default.scss";
@import "@/scss/hide.scss";

.my-rating-application {
  width: 100%;
  height: 100%;
  @include flex-layout-vertical($align: normal, $justify: flex-start);

  &__divider {
    margin: $gap-primary 0;
    @include for-medium-screen() {
      margin: $gap-em 0;
    }
  }

  .my-rating-header {
    background-color: $color-lighter-grey;
    border-radius: 0.5em;
    padding: $gap-em;

    &__title {
      font-size: $fz-title_4;
      font-weight: $fw-900;

      @include for-large-screen() {
        font-size: $fz-title_2;
      }
      @include for-xlarge-screen() {
        font-size: $fz-large;
      }
    }
    &__subtitle {
      color: $color-red-main;
      margin-bottom: $gap-primary;
      font-weight: $fw-800;
      font-size: $fz-text;

      @include for-large-screen() {
        font-size: $fz-title_5;
      }
      @include for-xlarge-screen() {
        margin-bottom: $gap-em;
        font-size: $fz-title_3;
      }
    }
    &__description {
      margin: 0;
      font-size: $fz-small;
      @include for-xlarge-screen() {
        font-size: $fz-primary;
      }
    }
  }

  /** Раздел "Рейтинг с анкетой" */
  &__with-form {
    @include flex-layout-horizontal($align: normal, $gap: calc(2 * #{$gap-em}));
    height: auto;
    overflow-y: auto;

    .rating-sidebar,
    .rating-form {
      border: 1px solid $color-lightgrey;
      border-radius: 0.5em;
      box-shadow: $section-box-shadow;

      padding: $gap-em;

      @include for-xlarge-screen() {
        padding: calc(2 * #{$gap-em});
      }
    }

    .rating-form {
      overflow: hidden;
      @include flex-layout-vertical(flex-start, normal);
      width: 100%;

      @include for-xlarge-screen() {
        width: 50vw;
      }

      &__fields {
        overflow-y: auto;
        padding: 0 $gap-em $gap-em 0;
        @include flex-layout-vertical(flex-start, normal);

        label {
          font-size: $fz-primary;
          font-weight: $fw-600;
          @include for-large-screen() {
            font-size: $fz-text;
          }
        }
      }

      &__submit-btn {
        margin-right: $gap-em;
        margin-top: auto;
      }
    }

    .rating-sidebar {
      min-width: 300px;
      width: min-content;
      align-self: flex-start;

      &__detail-group {
        @include flex-layout-horizontal(space-between);
        p {
          margin: 0;
        }
        p:first-of-type {
          width: 60%;
          font-weight: 600;
        }
      }

      &__percent {
        font-size: $fz-text;
        padding: 2px $gap-primary;
        margin: 0;
      }
    }
  }

  /** Раздел "Итоговый рейтинг" */
  &__total {
    width: 100%;
    margin: 0 auto;
    @include flex-layout-vertical(flex-start, normal);
    padding: calc(2 * #{$gap-em});
    border: 1px solid $color-lightgrey;
    border-radius: 0.5em;
    box-shadow: 0 2px 12px 0 rgba(48, 50, 51, 0.1);

    @include for-xlarge-screen() {
      width: 80vw;
    }

    .rating-list {
      width: 100%;
      @include flex-layout-vertical(flex-start, normal);

      @include for-xlarge-screen() {
        width: 60%;
      }
  
      &__header,
      &__item {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        p:not(:first-of-type) {
          text-align: center;
        }
      }
      &__header {
        width: 100%;
        padding: $gap-em 0;
        border-bottom: 1px solid $color-grey;

        p {
          margin: 0;
          font-weight: 600;
        }
      }

      &__content {
        padding: 0;
        @include flex-layout-vertical(flex-start, normal);
      }
    }
  }
}
