@import "@/scss/default.scss";
@import "@/scss/mixin/form.scss";
@import "@/scss/mixin/layout.scss";

.ils-rating {
  @include flex-justify-align();

  color: $color-white;
  border-radius: 50%;
  width: 45px;
  min-width: 45px;
  height: 45px;
  font-weight: $fw-700;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}