@import "@/scss/default.scss";
@import "@/scss/mixin/form.scss";
@import "@/scss/mixin/layout.scss";
@import "@/scss/mixin/screen-size.scss";
@import "@/scss/mixin/sidebar-filters.scss";

$social-services-menu-height: 105px;

.social-services {
  padding: $gap-em;
  height: $main-height;
  max-height: $main-height;
  overflow-y: scroll;
  overflow-x: hidden;

  @include flex-vertical($gap-em);

  &__toolbar {
    flex-wrap: wrap;
    @include for-small-screen() {
      @include flex-vertical();
      align-items: normal;
    }

    @include flex-horizontal($gap-em);
    align-items: flex-start;

    @include for-large-screen() {
      align-items: center;
    }

    .filters {
      @include for-large-screen() {
        display: none;
      }
    }

    &-form {
      @include flex-layout-vertical($align: flex-start);

      @include for-large-screen() {
        @include flex-layout-horizontal();
      }
    }

    &-status {
      min-width: 160px;
    }
  }

  &__filters {
    @include sidebar-filters();
    .filters-reset-button {
      margin-bottom: $gap-em;
    }
  }

  &__content {
    @include flex-horizontal($gap-em);
  }
}
