@import "@/scss/mixin/screen-size.scss";
@import "@/scss/mixin/layout.scss";
@import "@/scss/default.scss";


.admin-articles {
  flex: 1;
  position: relative;

  &-filter {
    width: 100%;
    margin-bottom: 20px;
  }

  &-content {
    padding: 15px;
    height: inherit;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    gap: 10px;
    margin: 0 auto;
  }

  &-crud {
    width: 100%;
    height: 100%;
    padding: 0 20px;
    border-radius: 8px;
  }

  &-title-article {
    margin-top: 15px;
  }

  &-card {
    width: 100%;

    img {
      height: 100%;
    }

    @include for-medium-screen() {
      width: calc(100% / 2 - 8px);
    }

    @include for-xlarge-screen() {
      width: calc(100% / 4 - 8px);
    }
  }

  &-icon {
    font-size: 20px;
  }

  &-sort {
    &-title {
      margin-bottom: 10px;
    }
  }
}

.admin-articles-form-modal {
  &__form {
    @include flex-layout-vertical();

    .ils-form-item {
      width: 100%;
    }
  }

  &__editor {
    margin-top: $gap-em;
  }
}