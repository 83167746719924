@import "@/scss/default.scss";

.ils-form-item {
  margin: 0;

  label {
    font-weight: $fw-600;
  }
  .ant-form-item-explain {
    width: min-content;
    flex: 1;
  }
}
