@import "@/scss/mixin/screen-size.scss";
@import "@/scss/mixin/layout.scss";
@import "@/scss/mixin/ellipsis.scss";
@import "@/scss/default.scss";

$content-mobile-max-w: 800px;
$sidebar-min-w: 400px;
$avatar-height: 300px;
$avatar-height_md: 180px;

/** раздел с подробной информацией о вакансии, водителе, организации */
@mixin detailed-info() {
  width: 100%;
  max-width: $content-mobile-max-w;
  margin: 0 auto;
  @include flex-layout-vertical(flex-start, stretch);

  @include for-xxlarge-screen() {
    @include flex-layout-horizontal(normal, flex-start);
    max-width: $max-page-content-width;
  }

  p {
    margin: 0;
  }
}

/** Основной заголовок: название вакансии, ФИО водителя, название организации */
@mixin detailed-info-main-title() {
  width: 100%;
  font-weight: $fw-900 !important;
  font-size: $fz-title_2 !important;
  
  white-space: normal;
  @include no-overflow-ellipsis();

  @include for-medium-screen() {
    font-size: $fz-big !important;
  }
}

/** Основной блок (слева) с подробной информацией */
@mixin detailed-info-content() {
  flex: 1;
  height: 100%;
  @include flex-layout-vertical(flex-start, normal);

  &-group {
    padding: 1em;
    border-radius: 0.5em;
    border: 1px solid $color-lightgrey;
    box-shadow: 0 2px 12px 0 rgba(48, 50, 51, 0.1);
  }
}

/** Дополнительный блок (справа) - sidebar */
@mixin detailed-info-sidebar() {
  max-width: 100%;
  height: 100%;
  padding: 1em;
  border-radius: 0.5em;
  border: 1px solid $color-lightgrey;
  box-shadow: 0 2px 12px 0 rgba(48, 50, 51, 0.1);
  @include flex-layout-vertical(normal, stretch, 0);

  @include for-xxlarge-screen() {
    min-width: $sidebar-min-w;
  }
}

/** Секция внутри подробной информации (content) */
@mixin detailed-info-section() {
  padding: calc(0.5 * #{$gap-em}) 0;
  border-bottom: 1px solid $color-lightgrey;
  
  &:last-of-type {
    border-bottom: none;
  }

  @include for-medium-screen() {
    padding: calc(1.5 * #{$gap-em}) 0;
  }

  /** заголовок + контент */
  &-column {
    @include flex-layout-vertical($align: flex-start, $gap: $gap-em);
    }
    
  /** заголовок секции */
  &-title {
    font-size: $fz-title_5 !important;
    font-weight: $fw-800 !important;
    @include no-overflow-ellipsis();
    @include for-medium-screen() {
      font-size: $fz-title_3 !important;
    }
  }

  &-content {
    @include flex-layout-vertical($align: flex-start);
    @include for-medium-screen() {
      @include flex-layout-vertical($align: flex-start, $gap: $gap-em);
    }
  }
}

/** Аватар: аватар водителя, логотип организации */
@mixin detailed-info-avatar() {
  height: $avatar-height !important;
  aspect-ratio: 4/3;
  width: 100%;
  object-fit: cover;
  border-radius: $gap-primary;
  min-width: 100%;
  height: $avatar-height_md !important;

  @include for-medium-screen() {
    min-width: 400px;
    height: $avatar-height !important;
  }
}

/** Участник / Партнер Водителей России:
* badge - иконка рядом с заголовком;
* title - подзаголовок с описанием статуса;
*/
@mixin detailed-info-membership() {
  &-badge {
    vertical-align: text-bottom;
    margin-left: $gap-em;
  }
  &-title {
    white-space: normal;
    color: $color-accent !important;
  }
}
