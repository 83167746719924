@mixin flex-horizontal($gap: $gap-primary) {
  display: flex;
  gap: $gap;
}

@mixin flex-vertical($gap: $gap-primary) {
  @include flex-horizontal($gap);
  flex-direction: column;
}

@mixin flex-justify-align(
  $justify: center,
  $align: center,
  $gap: $gap-primary
) {
  @include flex-horizontal($gap);
  justify-content: $justify;
  align-items: $align;
}

@mixin flex-layout-horizontal(
  $justify: center,
  $align: center,
  $gap: $gap-primary
) {
  @include flex-horizontal($gap);
  flex-direction: row;
  @include flex-justify-align($justify, $align, $gap);
}

@mixin flex-layout-vertical(
  $justify: center,
  $align: center,
  $gap: $gap-primary
) {
  @include flex-vertical($gap);
  @include flex-justify-align($justify, $align, $gap);
}
