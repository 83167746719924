@import "@/scss/default.scss";
@import "@/scss/hide.scss";
@import "@/scss/mixin/form.scss";
@import "@/scss/mixin/screen-size.scss";
@import "@/scss/mixin/card/list.scss";

.driver-applies {
  @include card-list-vertical();

  &__toolbar {
    align-self: flex-start;
    max-width: 300px;
    width: 100%;
    margin-bottom: $gap-em;
  }

  &__list {
    @include card-list-vertical();

    &-header {
      display: grid;
      grid-template-columns: 40px 0.2fr 1fr 0.1fr;
      gap: $gap-primary;
      border: 1px solid #e9ecef;
      border-radius: 12px;
      padding: $gap-em calc(2 * #{$gap-em});

      p {
        margin: 0;
        font-weight: $fw-600;
        color: $color-grey;
        border-bottom: 2px $color-grey dotted;
        width: max-content;
      }
    }
  }

  &__card {
    display: grid;
    grid-template-rows: 0.7fr repeat(2, 1fr);
    align-items: center;
    border: 1px solid #e9ecef;
    border-radius: 12px;
    padding: $gap-primary;
    position: relative;
    gap: $gap-primary;

    @include for-medium-screen() {
      grid-template-columns: 40px 0.2fr minmax(200px, 1fr) 0.1fr;
      grid-template-rows: auto;
      padding: $gap-em calc(2 * #{$gap-em});
    }

    &-badge {
      position: absolute;
      top: 0;
      right: 0;
      display: block;

      @include for-medium-screen() {
        display: none;
      }
    }

    &-group {
      width: 100%;
      @include flex-layout-vertical($align: flex-start);
      &_center {
        align-items: flex-end;

        @include for-medium-screen() {
          align-items: center;
        }
      }
    }
    &-row {
      width: 100%;
      @include flex-layout-horizontal(flex-start);
    }

    p {
      margin: 0;
      width: max-content;
      white-space: normal;
    }
    a {
      width: max-content;
    }

    .driver-applies__name {
      font-size: $fz-text;
      font-weight: $fw-700;
      color: inherit;
    }

    .driver-applies__company,
    .driver-applies__id {
      font-weight: $fw-700;
      color: inherit;
    }

    .driver-applies__id {
      color: $color-darkgrey;
    }

    .driver-applies__rating {
      width: 20px;
      min-width: 20px;
      height: 20px;
      font-size: $fz-xs;
    }
  }
}
