@import "@/scss/default.scss";
@import "@/scss/mixin/form.scss";
@import "@/scss/mixin/layout.scss";
@import "@/scss/mixin/screen-size.scss";

$register-form-height: 1000px;

.auth {
  height: 100%;
  padding: 1rem;
  overflow-y: auto;
  @include flex-layout-horizontal();

  &-title {
    margin: 0;
    font-weight: $fw-800 !important;
  }

  &-form {
    @include form-white(50%);
    gap: $gap-primary;

    &__input {
      width: 100%;
    }

    &__button {
      width: 100%;
      margin-top: $gap-em;

      &:hover {
        opacity: 0.33;
      }
    }
    &_register {
      @media screen and (max-height: $register-form-height) {
        align-self: flex-start;
      }
      .consent-to-data-processing {
        &__checkbox {
          @include flex-layout-horizontal(flex-start, center, 0);
          label {
            span:nth-of-type(2) {
              padding-right: 4px;
            }
          }
        }
        &__link {
          text-decoration: underline;
        }
      }

      .ils-form__input_IsEmployee {
        max-width: 322px;
      }
    }
  }
}
