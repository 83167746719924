@import "@/scss/mixin/screen-size.scss";
@import "@/scss/mixin/layout.scss";
@import "@/scss/mixin/ellipsis.scss";
@import "@/scss/default.scss";

/** фото тс */
.vacancy__vehicle-photos {
  width: 100%;
  cursor: pointer;

  @include for-medium-screen() {
    max-width: 400px;
  }

  /** Главное фото */
  .vacancy__vehicle-main-photo {
    height: auto;
    img {
      height: auto;
    }

    @include for-medium-screen() {
      height: 300px;
      img {
        height: 300px;
        aspect-ratio: 4/3;
      }
    }
  }

  /** превью изображения */
  .vacancy__vehicle-previews-list {
    @include for-medium-screen() {
      flex-wrap: wrap;
      @include flex-layout-horizontal(flex-start, flex-start, $gap: 0);
    }

    .vacancy__vehicle-preview {
      position: relative;

      img {
        width: 100px;
        aspect-ratio: 4 / 3;
        object-fit: cover;
      }

      .vacancy__vehicle-preview-overlay {
        @include flex-layout-horizontal();

        position: absolute;
        inset: 0;
        background-color: rgba(0, 0, 0, 0.1);
        transition: 0.2s ease-in-out;
        p {
          color: $color-white;
          text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
        }
      }

      &:hover {
        .vacancy__vehicle-preview-overlay {
          background-color: rgba(0, 0, 0, 0);
        }
      }
    }
  }

  /** кнопка "еще фото", отображается в мобильной версии */
  &-button {
    display: block;
    margin-left: auto;

    @include for-medium-screen() {
      display: none;
    }
  }
}

/** Модальное окно со всеми фотографиями */
.vacancy__vehicle-photos_modal {
  position: relative;
  width: 100%;
  max-height: 70vh;
  aspect-ratio: 4 / 3;

  img {
    width: 100%;
    aspect-ratio: 4/3;
    object-fit: contain;
  }

  .vacancy__modal-arrow {
    position: absolute;
    top: 50%;
    z-index: 1;
    transform: translate(0, -50%);

    &.left {
      left: $gap-primary;
    }
    &.right {
      right: $gap-primary;
    }
  }
}
