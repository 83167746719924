@import "@/scss/default.scss";
@import "@/scss/hide.scss";
@import "@/scss/mixin/form.scss";
@import "@/scss/mixin/screen-size.scss";
@import "@/scss/mixin/card/list.scss";

.driver-search {
  &__list {
    @include card-list-vertical();
  }
  &__construct-icon {
    display: none;
    @include for-medium-screen() {
      display: block;
    }
  }
}

.add-review-form {
  max-height: 60vh;
  overflow-y: auto;
  @include flex-layout-vertical(stretch, center, 0);

  &_driver {
    min-width: 270px;
    display: flex;
    padding: 0;
    @include flex-layout-vertical(stretch, center, $gap-primary);

    @include for-medium-screen() {
      padding: $gap-em;
      @include flex-layout-horizontal($align: flex-start, $gap: $gap-em);
    }
  }
  &__rating {
    align-self: normal;
    text-align: center;
  }
}
