@import "@/scss/default.scss";
@import "@/scss/mixin/screen-size.scss";

.hide-small {
  @media (max-width: $small-screen-width) {
    display: none;
  }
}

.hide-medium {
  @media (max-width: $medium-screen-width) {
    display: none;
  }
}

.hide-large {
  @media (max-width: $large-screen-width) {
    display: none;
  }
}

.hide-xlarge {
  @media (max-width: $xlarge-screen-width) {
    display: none;
  }
}

.hide-xxlarge {
  @media (max-width: $xxlarge-screen-width) {
    display: none;
  }
}

.hide-xxlarge-100 {
  @media (max-width: $xxlarge-screen-width-100-em) {
    display: none;
  }
}

.hide-xxlarge-110 {
  @media (max-width: $xxlarge-screen-width-110-em) {
    display: none;
  }
}

.hide-wide {
  @media (max-width: $wide-screen-width) {
    display: none;
  }
}

