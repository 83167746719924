@font-face {
  font-weight: 300; /* Соответствует значению normal */
  font-family: 'Bitter';
  src: url("../fonts/Bitter-Thin.ttf");
}

@font-face {
  font-weight: 400; /* Соответствует значению normal */
  font-family: 'Bitter';
  src: url("../fonts/Bitter-Regular.ttf");  /* статьи, основной текст */
}

@font-face {
  font-weight: 600; /* Соответствует значению medium */
  font-family: 'Bitter';
  src: url("../fonts/Bitter-Medium.ttf");
}

@font-face {
  font-weight: 800; /* Соответствует значению bold */
  font-family: 'Bitter';
  src: url("../fonts/Bitter-ExtraBold.ttf");  /* подзаголовки */
}

@font-face {
  font-weight: 900; /* Соответствует значению bold */
  font-family: 'Bitter';
  src: url("../fonts/Bitter-Black.ttf"); /* головные заголовки */
}
