@import "@/scss/default.scss";
@import "@/scss/mixin/form.scss";
@import "@/scss/mixin/screen-size.scss";

.salary {
  @include flex-layout-horizontal(flex-start, flex-start);
  @include for-small-screen() {
    @include flex-layout-vertical($align: normal);
  }

  &__to {
    .ant-form-item-label {
      @include for-small-screen() {
        display: none !important;
      }
    }
  }
}
