@import "@/scss/default.scss";

@mixin for-small-screen {
  @media (max-width: $small-screen-width) {
    @content;
  }
}

@mixin for-medium-screen {
  @media (min-width: $medium-screen-width) {
    @content;
  }
}

@mixin for-large-screen {
  @media (min-width: $large-screen-width) {
    @content;
  }
}

@mixin for-xlarge-screen {
  @media (min-width: $xlarge-screen-width) {
    @content;
  }
}

@mixin for-xxlarge-screen {
  @media (min-width: $xxlarge-screen-width) {
    @content;
  }
}

@mixin for-xxlarge-screen-width-100-em {
  @media (min-width: $xxlarge-screen-width-100-em) {
    @content;
  }
}

@mixin for-xxlarge-screen-width-110-em {
  @media (min-width: $xxlarge-screen-width-110-em) {
    @content;
  }
}

@mixin for-wide-screen {
  @media (min-width: $wide-screen-width) {
    @content;
  }
}
