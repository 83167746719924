@import "@/scss/default.scss";
@import "@/scss/hide.scss";
@import "@/scss/mixin/form.scss";
@import "@/scss/mixin/screen-size.scss";
@import "@/scss/mixin/card/list.scss";

$modal-paddings: 40px;
$steps-horizontal-height: 88px;
$steps-vertical-height: 144px;
$steps-content-margin: 32px;
$buttons: 32px;
$steps-switch-layout-width: 574px;

.vacancies {
  &-form {
    /** Содержимое формы каждого шага */
    &__content {
      @include flex-layout-vertical(flex-start, stretch);
      max-height: calc(
        90vh - #{$modal-paddings} - #{$steps-horizontal-height} - #{$steps-content-margin} -
          #{$buttons}
      );
      overflow: hidden;
      overflow-y: scroll;

      @media (max-width: $steps-switch-layout-width) {
        max-height: 100%;
        overflow-y: auto;
      }
    }

    &__header {
      padding: $gap-primary 0;
      &-subtitle {
        color: $color-red-main;
      }
    }

    /** Прячем описание шагов на мобильных экранах */
    .ant-steps-item-description {
      .vacancies-form__step-subtitle {
        margin: 0;
        max-width: 96px !important;
        text-wrap: wrap;
        font-size: $fz-small;
        font-weight: $fw-600;
        line-height: 1.2;
      }
      @media (max-width: $steps-switch-layout-width) {
        display: none;
      }
    }

    /** переписываем дефолтный стиль min-width:420px */
    .ant-pro-steps-form-container {
      min-width: 100% !important;
    }

    &__card {
      width: 100%;
      margin: 0 $gap-em $gap-em 0;
      &-body {
        @include flex-layout-vertical(flex-start, normal, $gap-em);
      }

      @include for-small-screen() {
        margin: 0 $gap-primary $gap-primary 0;
      }

      .ant-card-head-title {
        @include for-small-screen() {
          width: min-content;
          text-wrap: wrap;
        }
      }
    }
  }
}