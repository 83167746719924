@import "@/scss/mixin/screen-size.scss";
@import "@/scss/mixin/layout.scss";
@import "@/scss/default.scss";

$not-found-icon-size_sm: 280px;
$not-found-icon-size_md: 420px;

.not-found {
  @include flex-layout-vertical($align: normal, $justify: space-between);
  height: 100%;
  overflow: auto;
  background-color: $color-white;

  &__content {
    @include flex-layout-vertical();
    margin: auto;
    padding: $gap-em calc(2 * #{$gap-em});
    border-radius: 2em;
    text-align: center;
    background: $color-white;
    box-shadow: 0 24px 48px $color-black-transparent;

    @include for-small-screen() {
      padding: $gap-primary $gap-em;
      border-radius: 1em;
    }
    @include for-large-screen() {
      padding: calc(2 * #{$gap-em}) calc(3 * #{$gap-em});
      border-radius: 3em;
    }

    h1 {
      color: $color-darker-grey;
      margin: 0 0 $gap-primary;
      font-size: $fz-title_3;

      @include for-large-screen() {
        margin: 0 0 $gap-em;
        font-size: $fz-title_1;
      }
    }

    svg {
      width: $not-found-icon-size_md;
      height: auto;
      @include for-small-screen() {
        width: $not-found-icon-size_sm;
      }
      @include for-large-screen() {
        width: auto;
      }
    }
  }

  &__buttons {
    @include flex-layout-horizontal();

    button {
      min-width: 120px;
    }
  }
}
