@import "@/scss/mixin/screen-size.scss";
@import "@/scss/mixin/layout.scss";
@import "@/scss/mixin/ellipsis.scss";
@import "@/scss/default.scss";
@import "@/scss/mixin/detailed-info/detailed-info.scss";

.vacancy {
  @include detailed-info();

  /** header */
  &__header {
    /** название вакансии + id */
    &-row {
      @include flex-layout-vertical($align: flex-start);
      @include for-medium-screen() {
        @include flex-layout-horizontal(space-between);
      }
    }

    /** Основной заголовок */
    .vacancy__title {
      @include detailed-info-main-title();
    }
  }

  /** Участник организации Водители России */
  &__partnership {
    @include detailed-info-membership();
  }

  /** id */
  &__ribbon {
    font-size: $fz-title_5;
    line-height: $fz-title_5;
    padding: 4px $gap-primary;
    @include for-medium-screen() {
      font-size: $fz-title_3;
      line-height: $fz-title_3;
    }
  }

  /** основной блок */
  &__content {
    @include detailed-info-content();
  }

  /** подраздел, напр. основная информация о вакансии, информация о тс */
  &__section {
    @include detailed-info-section();

    /** весь раздел + аватар */
    &-row {
      @include flex-layout-vertical(flex-start, flex-start);
      @include for-xlarge-screen() {
        @include flex-layout-horizontal(space-between, flex-start);
      }
    }
  }

  /** боковой раздел */
  &__sidebar {
    @include detailed-info-sidebar();

    .vacancy__status-component {
      .vacancy__status-group {
        @include flex-layout-horizontal(space-between);
      }
      .vacancy__status-title {
        color: $color-darkgrey;
        font-weight: $fw-500;
      }
    }

    .vacancy__prices {
      @include flex-layout-horizontal(space-between);
      flex-wrap: wrap;

      .vacancy__price {
        font-size: $fz-title_2 !important;
        font-weight: 600;
        @include for-medium-screen() {
          font-size: $fz-big !important;
        }
      }
    }

    .vacancy__phone {
      @include flex-layout-vertical();
      font-size: $fz-text;

      @include for-medium-screen() {
        font-size: $fz-title_5;
      }
    }

    .vacancy__owner {
      @include flex-layout-horizontal(space-between);

      &-content {
        width: 100%;
        overflow: hidden;
        @include flex-layout-vertical($align: flex-start);
      }
      &-name {
        color: $color-blue;
        font-size: $fz-text;
        font-weight: $fw-700;
      }
      &-rating {
        width: 20px;
        min-width: 20px;
        height: 20px;
        font-size: $fz-xs;
      }
      &-row {
        width: 100%;
        @include flex-layout-horizontal(flex-start);
      }
      &-avatar {
        @include flex-layout-horizontal();
        width: 50px;
        height: 50px;
        min-width: 50px;
      }
    }
  }

  /** Отклики */
  &__applies {
    display: grid;
    grid-template-columns: 1fr;

    @include for-medium-screen() {
      grid-template-columns: repeat(auto-fit, minmax(510px, 1fr));
    }
  }

  &__comment {
    &-form {
      @include flex-vertical();
      button {
        margin-top: $gap-primary;
        align-self: flex-end;
      }
    }
    &-alert {
      margin-bottom: $gap-em;
    }
  }
}
