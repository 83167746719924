@import "@/scss/mixin/screen-size.scss";
@import "@/scss/mixin/layout.scss";
@import "@/scss/default.scss";
@import "@/scss/hide.scss";

$icon-wrapper-size: 52px;
$icon-size: 20px;

.card-with-icon {
  padding: $gap-em;
  text-align: center;
  @include flex-layout-vertical();
  border: 1px solid $color-lightgrey;
  border-radius: 4px;

  @include for-medium-screen() {
    text-align: start;
    @include flex-layout-horizontal(flex-start, center, 0);
  }

  &-icon {
    width: $icon-wrapper-size;
    min-width: $icon-wrapper-size;
    height: $icon-wrapper-size;
    @include flex-layout-horizontal(center, center, 0);
    border-radius: 50%;
    background-color: $color-main;
    box-shadow: 0 2px 0 rgba(0, 6, 15, 0.53);
    & > div {
      height: $icon-size;
    }
    svg {
      fill: $color-white;
      height: $icon-size;
      width: $icon-size;
    }
  }

  &-title {
    margin: 0 0 $gap-primary 0;
    font-size: $fz-title_6;
    @include for-medium-screen() {
      font-size: $fz-title_5;
    }
  }

  &-value {
    margin: 0;
    font-size: $fz-title_5;
    @include for-medium-screen() {
      font-size: $fz-title_3;
    }
  }

  &-divider {
    height: auto;
    align-self: stretch;
    margin: 0 calc(2 * #{$gap-em}) 0 $gap-em;
  }
}
