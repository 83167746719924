@import "@/scss/default.scss";

.burger {
  width: 28px;
  height: 20px;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
}

.burger span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: $color-main;
  border-radius: 2px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.burger span:nth-child(1) {
  top: 0px;
  transform-origin: left center;
}

.burger span:nth-child(2) {
  top: 7px;
  transform-origin: left center;
}

.burger span:nth-child(3) {
  top: 14px;
  transform-origin: left center;
}

.burger.open span:nth-child(1) {
  transform: rotate(45deg);
  top: -2px;
  left: 5px;
}

.burger.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

.burger.open span:nth-child(3) {
  transform: rotate(-45deg);
  top: 18px;
  left: 5px;
}
