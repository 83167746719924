@import "@/scss/default.scss";
@import "@/scss/mixin/layout.scss";

.sider-banner-image {
  @include flex-layout-vertical();
  position: relative;
  max-height: 400px;
  max-width: 380px;
  &__header {
    max-width: 320px;
    padding: $gap-primary $gap-em;
    border-radius: $gap-primary;
    background-color: $bg-color-footer;
    @include flex-layout-vertical();

    p {
      margin: 0;
      text-align: center;
      font-size: $fz-text;
      font-weight: $fw-800;
      text-transform: uppercase;
      color: $color-darkgrey;

      &:last-of-type {
        max-width: 80%;
        color: $color-accent;
      }
    }
  }

  .sider-carousel {
    position: relative;
    width: 100%;
    aspect-ratio: 4 / 3;

    &__arrow {
      position: absolute;
      top: 50%;
      z-index: 1;
      transform: translate(0, -50%);

      &.left {
        left: $gap-primary;
      }
      &.right {
        right: $gap-primary;
      }
    }

    img {
      width: 100%;
      aspect-ratio: 4/3;
      object-fit: contain;
    }
  }
}
