@import "@/scss/default.scss";
@import "@/scss/hide.scss";
@import "@/scss/mixin/layout.scss";
@import "@/scss/mixin/screen-size.scss";

$section-head-horizontal-menu-item-height: 105px;
$section-head-horizontal-menu-item-padding: 15px;
$section-head-horizontal-menu-item-min-width: 150px;
$section-head-horizontal-menu-item-width: fit-content;


.menu {
  &_section-head-horizontal {
    justify-content: space-around;

    .ant-menu {
      &-item {
        box-sizing: border-box;
        @include flex-layout-vertical(start, center);

        padding: $section-head-horizontal-menu-item-padding;
        min-width: $section-head-horizontal-menu-item-min-width;
        width: $section-head-horizontal-menu-item-width;
        height: $section-head-horizontal-menu-item-height;
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }

      .anticon {
        @include for-small-screen() {
          display: none;
        }
      }
    }
  }
}