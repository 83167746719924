@import "@/scss/mixin/screen-size.scss";
@import "@/scss/mixin/layout.scss";
@import "@/scss/default.scss";
@import "@/scss/hide.scss";

$photo-fixed-w-sm-screen: 296px;

.contacts {
  width: 100%;
  height: 100%;
  @include flex-layout-vertical($align: center, $justify: flex-start);

  &__section {
    width: 100%;
    max-width: $max-section-width;
    padding: $gap-em;
    border: 1px solid $color-lightgrey;
    border-radius: 0.5em;
    box-shadow: $section-box-shadow;

    &-header {
      padding: calc(2 * #{$gap-em}) $gap-em;
      border-radius: 4px;
      background-color: $color-lighter-grey;
    }

    &-title {
      font-size: $fz-title_3 !important;
      font-weight: $fw-900 !important;
      @include for-medium-screen() {
        font-size: $fz-title_1 !important;
      }
    }

    &-content {
      @include flex-layout-vertical(flex-start, normal, $gap-em);
      flex-direction: column-reverse;
      padding: $gap-primary 0;

      @include for-medium-screen() {
        @include flex-layout-horizontal(
          space-between,
          flex-start,
          calc(2 * #{$gap-em})
        );
        padding: $gap-em 0;
      }

      @include for-xlarge-screen() {
        @include flex-layout-horizontal(
          space-between,
          flex-start,
          calc(5 * #{$gap-em})
        );
        padding: $gap-em;
      }
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    @include flex-layout-vertical(flex-start, normal, $gap-primary);

    @include for-medium-screen() {
      @include flex-layout-vertical(flex-start, normal, calc(2 * #{$gap-em}));
    }
  }

  &__president {
    &-biography {
      width: 100%;
      &-header {
        @include flex-layout-vertical(flex-start, normal, $gap-em);
        @include for-medium-screen() {
          display: block;
        }
      }

      &-name {
        font-weight: $fw-800 !important;
      }
      
      h4 {
        font-weight: $fw-800 !important;
        color: $color-darkgrey;
      }
      
      &-subtitle {
        font-weight: $fw-800 !important;
        color: $color-accent !important;
      }

      &-text {
        p {
          font-size: $fz-title_6;
          white-space: break-spaces;
          line-height: 1.6;
          width: 100%;
          white-space: break-spaces;
        }
      }
    }

    &-photo-form {
      @include flex-layout-vertical(flex-start, normal, calc(2 * #{$gap-em}));
    }

    &-photo {
      display: block;
      width: $photo-fixed-w-sm-screen;
      max-width: $photo-fixed-w-sm-screen;
      border-radius: $gap-em;
      overflow: hidden;

      @include for-medium-screen() {
        display: none;
      }

      &.hide {
        display: none;
        @include for-medium-screen() {
          display: block;
        }
      }

      img {
        border: 3px solid $color-lightgrey;
        border-radius: $gap-em;
        object-fit: cover;
      }
    }
  }

  &__form {
    width: 100%;
    min-width: $photo-fixed-w-sm-screen;
    @include flex-layout-vertical(flex-start, normal, 0);
    padding: $gap-em;
    border: 1px solid $color-lightgrey;
    border-radius: $gap-primary;


    @include for-medium-screen() {
      width: $photo-fixed-w-sm-screen;
    }


    &_wide {
      @include for-medium-screen() {
        width: 420px;
      }
    }

    &_large {
      @include for-medium-screen() {
        width: 720px;
      }
    }

    &-title {
      font-weight: $fw-800 !important;
    }

    &-subtitle {
      margin: 0;
      font-size: $fz-small;
    }

    &-divider {
      margin: $gap-em 0 calc(2 * #{$gap-em});
    }

    &-button {
      margin-top: calc(2 * #{$gap-em});
    }
  }
}
