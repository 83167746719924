@import "@/scss/default.scss";
@import "@/scss/mixin/layout.scss";

.ils-file-dragger-upload {
  @include flex-vertical();

  &__dropzone {
    &-heading {
      @include flex-layout-horizontal();
      font-weight: $fw-600;
    }

    &.disabled {
      color: $color-input-active;
    }
  }
}
