@mixin overflow-ellipsis() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin no-overflow-ellipsis() {
  overflow: auto;
  white-space: normal;
  text-overflow: initial;
}
