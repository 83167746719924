@import "@/scss/default.scss";
@import "@/scss/hide.scss";
@import "@/scss/mixin/form.scss";
@import "@/scss/mixin/screen-size.scss";
@import "@/scss/mixin/card/list.scss";
@import "@/scss/mixin/sidebar-filters.scss";

.admin-vacancies {
  &__list {
    @include card-list-vertical();
  }

  &__toolbar {
    margin-bottom: $gap-em;

    @include for-small-screen() {
      @include flex-vertical();
    }

    @include flex-horizontal($gap-em);

    .filters-button {
      @include for-large-screen() {
        display: none;
      }
    }
    &-filter {
      min-width: 200px;
      margin: 0;
    }
  }

  &__filters {
    @include sidebar-filters();
  }
  .filters-reset-button {
    margin-bottom: $gap-em;
  }

  &__content {
    @include flex-layout-horizontal(flex-start, flex-start, $gap-em);
    .ant-spin-nested-loading {
      width: 100%;
    }
  }
}
