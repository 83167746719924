@import "@/scss/default.scss";
@import "@/scss/hide.scss";
@import "@/scss/mixin/form.scss";
@import "@/scss/mixin/screen-size.scss";
@import "@/scss/mixin/card/list.scss";

$card-image-width: 225px;
$card-rating-width: 45px;
$card-rating-width_sm: 20px;
$card-profile-width: 200px;
$card-info-width: calc(
  #{$card-image-width} + #{$card-rating-width} + #{$card-profile-width}
);
$card-info-clump: 5;
$card-profile-logo-size: 50px;
$card-profile-logo-size_lg: 100px;

@mixin line-clamp($clamp: $card-info-clump) {
  display: -webkit-box;
  -webkit-line-clamp: $clamp;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ils-card {
  display: grid;
  gap: $gap-em;
  grid-template-columns: $card-image-width minmax($card-info-width, 1fr) $card-rating-width $card-profile-width;
  grid-template-rows: 1fr;
  max-width: 100%;
  min-height: fit-content;
  border: 1px solid $color-lightgrey;
  padding: 1em;
  position: relative;

  &:hover {
    background: $color-lightgrey;
    transition: background 1s ease 0s;
  }

  &.rows {
    grid-template-rows: 1fr 32px;

    @include for-small-screen() {
      grid-template-rows: min-content min-content min-content 32px;
    }
  }

  h4 {
    white-space: normal;
  }

  @include for-small-screen() {
    h3 {
      font-size: 20px;
    }
    h4 {
      font-size: 18px;
    }
    h5 {
      font-size: 16px;
    }

    font-size: 12px;
    gap: $gap-primary;
    grid-template-columns: 1fr;
  }
  @include for-medium-screen() {
    grid-template-columns: $card-image-width minmax(calc(#{$card-info-width} / 3), 1fr) $card-rating-width;
  }

  @include for-large-screen() {
    grid-template-columns: $card-image-width minmax(calc(#{$card-info-width} / 3), 1fr) $card-rating-width;
  }

  @include for-xlarge-screen() {
    grid-template-columns: $card-image-width minmax(calc(#{$card-info-width} / 2), 1fr) $card-rating-width $card-profile-width;
  }

  &__cover {
    @include flex-layout-vertical(center, center);

    @include for-small-screen() {
      @include flex-layout-vertical(flex-start, flex-start);
    }
  }

  &__info {
    @include flex-vertical();
    overflow: hidden;

    &-title-wrapper {
      @include flex-layout-horizontal(flex-start);
    }

    &-title {
      font-weight: $fw-800 !important;
    }

    &-description {
      @include line-clamp();
      margin: 0;
    }

    &-main-subtitle {
      max-width: 430px;
      @include flex-layout-vertical(flex-start, flex-start, 0);
      align-self: flex-start;
      line-height: 1.1;
      white-space: normal;
      font-size: $fz-title_6 !important;
      span {
        color: $color-accent;
      }
    }

    &-subtitle {
      font-size: $fz-title_6 !important;
    }
  }

  &__ratings {
    @include flex-vertical();

    @include for-small-screen() {
      flex-direction: row;
    }
  }

  &__profile-rating {
    @include flex-horizontal();
    justify-content: space-between;
    overflow: hidden;

    &-row {
      @include flex-layout-horizontal();
      overflow: hidden;
    }

    @include for-medium-screen() {
      display: none;
    }

    &-value {
      width: $card-rating-width_sm !important;
      min-width: $card-rating-width_sm !important;
      height: $card-rating-width_sm !important;
      font-size: $fz-xs;
    }
  }

  &__profile {
    max-width: inherit;
    @include for-large-screen() {
      @include flex-vertical();
    }
  }

  &__profile-img {
    object-fit: cover;
    border-radius: 4px;

    width: $card-profile-logo-size_lg !important;
    height: $card-profile-logo-size !important;

    &_small {
      width: $card-profile-logo-size !important;
      height: $card-profile-logo-size !important;
    }
    .success-icon {
      svg {
        fill: $color-dark-green;
        width: 20px;
        height: 20px;
      }
    }
  }

  &__actions {
    @include flex-horizontal();
  }

  &__link {
    position: absolute;
    inset: 0;
  }

  &__ribbon {
    position: absolute;
    left: 0;
    top: 0;
  }
}
