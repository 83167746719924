@import "@/scss/mixin/screen-size.scss";
@import "@/scss/mixin/layout.scss";
@import "@/scss/default.scss";

.sider-banners-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  @include flex-layout-horizontal(flex-start, normal, $gap-em);
  overflow-x: scroll;

  @include for-xlarge-screen() {
    overflow-x: unset;
    @include flex-layout-vertical(
            $align: normal,
            $justify: flex-start,
            $gap: calc(2 * #{$gap-em})
    );
  }
}
