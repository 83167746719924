@import "@/scss/default.scss";
@import "@/scss/mixin/layout.scss";

.useful-link {
  width: 120px;
  &__group {
    @include flex-layout-vertical();
    text-align: center;
  }

  img {
    object-fit: cover;
  }

  p {
    margin: 0;
    color: $color-darker-grey;
    font-weight: $fw-600;
  }
}
