$small-screen-width: 30em;
$medium-screen-width: 48em;
$large-screen-width: 62em;
$xlarge-screen-width: 80em;
$xxlarge-screen-width: 96em;
$xxlarge-screen-width-100-em: 100em;
$xxlarge-screen-width-110-em: 110em;
$wide-screen-width: 123.75em;

$bg-color: #ececec;
$bg-color-30: #ececec30;
$bg-color-footer: #ececec;

$color-main: rgb(47, 51, 64);
$color-red-main: #CE1416;
$color-main-medium: #ff4d4f;
$color-accent: rgb(210, 35, 40);
$color-main-light: #fdf0f1;
$color-black: #232323;
$color-black-transparent: #2323231a;
$color-darker-grey: #2a3d4f;
$color-darkgrey: #777;
$color-grey: #bdbdbd;
$color-lightgrey: #e9ecef;
$color-lighter-grey: #f6f6f6;
$color-white: #ffffff;
$color-green: #43cc4c;
$color-dark-green: #2f9e44;
$color-yellow: #fadb14;
$color-success: #50b993;

$color-input: rgba(232, 240, 254, 0.8);
$color-input-active: rgba(232, 240, 254, 1);
$color-input-hovered: rgba(232, 240, 254, 0.5);

$organization-rating-color: #2f9c8e;
$color-blue: #4c91ff;
$color-blue-transparent: #4c91ff47;

// --- fonts ---
// sizes
$fz-large: 38px;
$fz-big: 32px;
$fz-title_1: 28px;
$fz-title_2: 26px;
$fz-title_3: 24px;
$fz-title_4: 22px;
$fz-title_5: 20px;
$fz-title_6: 18px;
$fz-text: 16px;
$fz-primary: 14px;
$fz-small: 12px;
$fz-xs: 10px;

// styles
$fw-400: 400;
$fw-500: 500;
$fw-600: 600;
$fw-700: 700;
$fw-800: 700;
$fw-900: 900;

//gaps
$no-gap: 0;
$gap-primary: 0.5em;
$gap-em: 1em;
$gap-rem: 1rem;
$gap-form: 3rem;

$max-page-content-width: 1980px;
$max-section-width: 1552px;
$page-p-lg-y: 30px;
$page-p-md-y: $gap-primary;
$page-p-lg-x: 40px;
$page-p-md-x: $gap-em;

//height
$header-height: 7.5vh;
$footer-height: 7.96vh;
$main-height: calc(100vh - (#{$header-height}));

// box-shadow
$section-box-shadow: 0 2px 12px 0 rgba(48, 50, 51, 0.1);