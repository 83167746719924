@import "@/scss/mixin/screen-size.scss";
@import "@/scss/mixin/layout.scss";
@import "@/scss/mixin/ellipsis.scss";
@import "@/scss/default.scss";

.organization-apply {
  width: 100%;
  border: 1px solid $color-lightgrey;
  padding: 0.5em 0.5em 1em 1em;
  border-radius: 0.5em;
  max-width: 770px;

  /** Заголовок */
  &__header {
    @include flex-layout-vertical();

    @include for-medium-screen() {
      @include flex-layout-horizontal(space-between, flex-start);
    }

    &-left {
      @include flex-layout-horizontal(flex-start);
    }
    &-right {
      @include flex-layout-vertical();
      width: 100%;

      @include for-medium-screen() {
        width: max-content;
      }

      &-group {
        @include flex-layout-vertical($align: flex-end);
        align-self: flex-end;
      }
    }

    .organization-apply__name {
      width: 100%;
      display: inline-block;
      h5 {
        color: inherit;
      }
      
      .organization-apply__name-rating {
        @include flex-layout-horizontal(flex-start);
      }
    }

    .organization-apply__rating {
      width: 20px;
      min-width: 20px;
      height: 20px;
      font-size: $fz-xs;
    }

    .organization-apply__age {
      font-weight: 600;
      color: $color-darkgrey;
    }

    .organization-apply__phone {
      padding: 4px $gap-em;
      height: auto;
      font-size: $fz-title_5;
      font-weight: 600;
    }
    .organization-apply__applied {
      color: $color-dark-green;
    }
  }

  &__avatar {
    min-width: 60px;
  }

  /** Содержимое */
  &__content {
    @include flex-layout-vertical($align: flex-start);
    margin-bottom: $gap-em;
  }

  /** Кнопки */
  &__actions {
    @include flex-layout-horizontal(space-between);
    flex-wrap: wrap;

    button {
      width: min-content !important;

      @include for-small-screen() {
        width: 100% !important;
      }
    }

    &-left {
      @include for-small-screen() {
        width: 100%;
      }
      @include flex-layout-horizontal(flex-start);
    }
  }
}
