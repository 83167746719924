@import "@/scss/mixin/screen-size.scss";
@import "@/scss/mixin/layout.scss";
@import "@/scss/default.scss";

.sider-banner-survey {
  &__divider {
    margin: $gap-em 0;
  }

  &__submit-btn {
    margin-top: calc(2 * #{$gap-em});
  }
}
