@import "@/scss/mixin/screen-size.scss";
@import "@/scss/mixin/layout.scss";
@import "@/scss/mixin/ellipsis.scss";
@import "@/scss/default.scss";

.vacancy-status-tag {
  @include flex-layout-horizontal();
  width: min-content;
  padding: calc(0.5 * #{$gap-em}) $gap-em;
  font-size: $fz-title_6;
  border-radius: 12px;
}
