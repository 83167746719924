@import "@/scss/mixin/screen-size.scss";
@import "@/scss/mixin/layout.scss";
@import "@/scss/mixin/sidebar-filters.scss";
@import "@/scss/mixin/card/list.scss";

.admin-drivers {
  width: 100%;
  height: max-content;

  @include for-xlarge-screen() {
    height: inherit;
  }

  &__content {
    @include flex-layout-horizontal(flex-start, flex-start, $gap-em);
    position: relative;
  }

  &__toolbar {
    margin-bottom: $gap-em;

    @include for-small-screen() {
      @include flex-vertical();
    }

    @include flex-horizontal($gap-em);

    .filters-button {
      @include for-large-screen() {
        display: none;
      }
    }
  }

  &__filters {
    @include sidebar-filters();
  }

  &__list {
    @include card-list-vertical();
  }

  &-sort {
    &-title {
      margin-bottom: 10px;
    }
  }
}

.admin-edit-driver {
  @include flex-layout-vertical();

  &__fields {
    @include flex-layout-vertical();

    &_main {
      @include flex-layout-vertical($align: normal);
      width: 100%;
      max-width: 530px;
    }
  }

  &__membership {
    &-alert {
      color: $color-accent;
      font-weight: 600;
      margin: 0;
    }
    &-badge {
      margin-left: $gap-primary;
    }
  }

  &__buttons {
    @include flex-layout-horizontal(flex-start);
  }

  &__divider {
    span {
      font-weight: 600;
    }
    &::before,
    &::after {
      background-color: $color-black-transparent;
    }
  }

  &__verification {
    p {
      margin: 0;
      font-weight: $fw-600;
    }
    svg {
      width: 25px;
      height: 25px;
    }
    .success-icon {
      svg {
        fill: $color-dark-green;
      }
    }
  }
}
