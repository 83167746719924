@import "@/scss/default.scss";
@import "@/scss/hide.scss";
@import "@/scss/mixin/layout.scss";
@import "@/scss/mixin/screen-size.scss";
@import "@/scss/mixin/ellipsis.scss";

$header-button-height: 54px;
$header-action-button-width: 54px;

@mixin header-button() {
  max-width: 190px;
  height: $header-button-height;
  font-weight: $fw-600;
  box-shadow: none;
  @include flex-layout-horizontal(space-between, center);

  p {
    color: $color-white;
    margin: 0;
    padding: 0;
  }
}

.header-component {
  width: 100%;
  @include flex-justify-align(space-between, center, $gap-em);

  @include for-small-screen() {
    @include flex-justify-align(space-between, center, $gap-primary);
  }

  @include for-large-screen() {
    padding: 0 40px;
  }

  &__left,
  &__right {
    min-width: fit-content;
    @include flex-justify-align(center, center, $gap-em);
  }

  &__left {
    cursor: pointer;
  }

  &__login-as {
    line-height: 1;
  }

  &__logo {
    @include flex-justify-align(center, center, $gap-em);

    &:link,
    &:visited,
    &:hover,
    &:active {
      color: $color-white;
    }

    img {
      display: block;
    }

    span {
      display: none;
      @include for-large-screen() {
        display: block;
        font-size: $fz-title_4;
        font-weight: $fw-700;
      }
    }
  }

  &__company {
    font-weight: $fw-700;
    @include for-xxlarge-screen-width-110-em() {
      @include flex-justify-align(space-between, center, 10px);
    }

    & > span {
      display: none;
      @include for-xxlarge-screen-width-110-em() {
        display: inline;
      }
    }
  }

  &__organization {
    display: none;
    @include for-xxlarge-screen-width-100-em() {
      @include flex-justify-align(space-between, center, $no-gap);
      background: $color-white;
      height: $header-button-height;
      color: $color-darker-grey;
      font-weight: $fw-500;
      border-radius: 6px;
      padding: 10px;
    }

    .organization {
      &__social {
        max-width: 50px;
        white-space: break-spaces;
        line-height: 1.1;
        border-left: 2px solid $color-darker-grey;
        padding-left: 10px;
      }

      &__rating {
        @include flex-justify-align(center, center, $no-gap);
        margin-left: 65px;
      }

      &-name,
      &-user-name {
        margin: 0 10px;
        @include overflow-ellipsis();
        line-height: normal;
        max-width: 200px;
      }
      &-name {
        margin-top: $gap-primary;
      }
    }
  }

  &__names {
    cursor: pointer;
  }

  &__auth {
    min-width: 210px;
    @include flex-layout-vertical(normal, stretch, $gap-em);

    &-header-button {
      box-shadow: 0px 0px 16px 2px rgba(0, 6, 15, 0.53);
      padding: 0;
    }

    &-button {
      @include flex-layout-horizontal(flex-start, center, $gap-primary);
      height: $header-button-height;
      font-size: $fz-title_6;
    }
  }

  button {
    @include header-button();
  }

  &__services {
    display: none;
    @include for-xlarge-screen() {
      @include flex-horizontal(15px);
      margin-right: 15px;
    }

    button {
      @include header-button();
      min-width: 190px;
      text-align: left;
      line-height: 1;
      white-space: break-spaces;
      @include flex-justify-align(flex-start, center, $no-gap);
    }
  }

  &__actions {
    @include flex-horizontal(10px);
    button {
      justify-content: center;
      width: auto;

      @include for-medium-screen() {
        width: $header-action-button-width !important;
      }
    }
  }
}

.header-profile-menu {
  &__title {
    margin-bottom: $gap-primary !important;
    font-weight: $fw-800 !important;
  }
  &__details {
    margin: 0 0 $gap-em 0;
    padding: 0;
  }
  p {
    margin: 0;
  }

  &__row {
    max-width: 350px;
    @include flex-horizontal();
  }
  &__buttons {
    @include flex-layout-vertical(center, stretch);
  }
}
