@import "@/scss/default.scss";
@import "@/scss/mixin/layout.scss";
@import "@/scss/mixin/screen-size.scss";

.news-header {
  margin: 0;
  padding: $page-p-md-y $page-p-md-x;
  @include flex-layout-horizontal($justify: space-between);

  @include for-xlarge-screen() {
    margin-bottom: $gap-em;
    padding: 0;
  }
}
