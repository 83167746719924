@import "@/scss/default.scss";
@import "@/scss/mixin/layout.scss";
@import "@/scss/mixin/screen-size.scss";

.admin-organization {
  &-form {
    flex: 1;
    width: 100%;
    @include flex-layout-vertical($align: normal);

    @include for-xlarge-screen() {
      @include flex-layout-horizontal(
        $justify: flex-start,
        $align: flex-start,
        $gap: $gap-em
      );
    }

    h3 {
      text-align: center;
      color: $color-black;
      font-size: $fz-text;
    }

    &__divider {
      margin: 0.5em 0;
    }

    &__column {
      @include flex-layout-vertical();

      &_wide {
        width: 100%;
        align-items: center;
        @include for-xlarge-screen() {
          @include flex-layout-vertical(normal, flex-start, $gap-em);
        }
      }
    }

    &__fields {
      width: 100%;
      max-width: 450px;
      &-group {
        width: 100%;
        @include flex-layout-vertical(normal, stretch);
      }
    }

    &__buttons {
      @include flex-layout-horizontal(flex-start);
    }

    &__alert {
      margin: 0;
      color: $color-accent;
      font-weight: 600;
    }
  }

  &-users {
    @include flex-layout-vertical(flex-start, center, $gap-em);
    &__list {
      @include flex-layout-horizontal($align: flex-start);
      flex-wrap: wrap;
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
  }
}
