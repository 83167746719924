@import "@/scss/mixin/screen-size.scss";
@import "@/scss/mixin/layout.scss";
@import "@/scss/default.scss";

.user-card {
  min-width: 300px;
  p {
    margin: 0;
  }
  &-header {
    @include flex-layout-horizontal(space-between);

    &-name,
    &-position {
      overflow: hidden;
      white-space: normal;
      text-overflow: ellipsis;
      width: min-content;
      line-height: 1.2;
    }
    &-name {
      flex: 1;
      font-weight: $fw-800 !important;
    }
    &-position {
      font-size: $fz-small;
      font-weight: $fw-800 !important;
      color: $color-darkgrey;
    }
  }

  &-content {
    @include flex-layout-horizontal(space-between, normal);
  }

  &-details {
    flex: 1;
    &-group {
      @include flex-layout-vertical(flex-start, normal);

      p:first-of-type {
        color: $color-main;
        font-weight: $fw-600;
      }
    }
  }

  &-divider {
    margin: $gap-primary;
  }
}
