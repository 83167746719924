@import "@/scss/mixin/screen-size.scss";
@import "@/scss/mixin/layout.scss";
@import "@/scss/default.scss";

.under-construction-page {
  height: 100%;
  @include flex-layout-vertical($align: normal, $justify: space-between);
  background-color: $bg-color;
  &__content {
    width: 100%;
    margin: 0 auto;
    max-width: $max-page-content-width;
  }

  &__header {
    position: relative;
    width: 100%;
    background-color: $color-white;
    padding: 2em;
    text-align: center;
    box-shadow: 0 24px 48px #e6ebf566;
    h1 {
      margin: 0;
      @include for-small-screen() {
        font-size: $fz-title_5;
      }
    }
  }

  &__go-back {
    position: absolute;
    top: 50%;
    left: $gap-em;
    transform: translate(0, -50%);
    @include for-small-screen() {
      left: $gap-primary;
    }
  }
  &__group {
    @include flex-layout-horizontal($gap: $gap-em);
    svg {
      width: 30px;
      height: 30px;
      fill: $color-main;
    }
    @include for-small-screen() {
      gap: $gap-primary;
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}
