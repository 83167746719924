@import "@/scss/mixin/screen-size.scss";
@import "@/scss/mixin/layout.scss";
@import "@/scss/mixin/ellipsis.scss";
@import "@/scss/default.scss";
@import "@/scss/mixin/detailed-info/detailed-info.scss";

.driver-details {
  @include detailed-info();

  /** header */
  &__header {
    /** Основной заголовок */
    .driver-details__title {
      @include detailed-info-main-title();
    }
  }

  /** Партнер Водителей России */
  .driver-details__membership {
    @include detailed-info-membership();
  }

  /** основной блок */
  &__content {
    @include detailed-info-content();
  }

  /** подраздел, напр. основная информация о водителе, водительское удостоверение */
  &__section {
    @include detailed-info-section();

    &-content_experience {
      align-self: stretch;
    }

    &-text {
      color: $color-darkgrey;
      font-size: $fz-primary;

      @include for-medium-screen() {
        font-size: $fz-title_6;
      }
    }
  }

  /** боковой раздел */
  &__sidebar {
    @include detailed-info-sidebar();

    .driver-details {
      &__salary-title {
        margin-bottom: $gap-primary;
        color: $color-darker-grey;
      }

      &__salaries {
        @include flex-layout-horizontal(space-between);
        flex-wrap: wrap;
      }

      &__salary {
        font-size: $fz-title_2;
        font-weight: 600;
        @include for-medium-screen() {
          font-size: $fz-big !important;
        }
      }

      &__no-salary {
        font-size: $fz-title_6;
        color: $color-darkgrey;
      }

      &__phone {
        @include flex-layout-vertical();
        font-size: $fz-text;

        @include for-medium-screen() {
          font-size: $fz-title_5;
        }
      }

      &__start-tag {
        margin-top: $gap-em;
        background-color: $color-green;
        color: $color-white;
        padding: $gap-primary $gap-em;
        font-size: $fz-text;
      }

      &__rating {
        @include flex-layout-horizontal(space-between, flex-start);
        margin-bottom: $gap-em;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  &__avatar {
    @include detailed-info-avatar();
  }

  &__row {
    border-bottom: 1px solid $color-lightgrey;
    @include flex-layout-vertical($align: flex-start);
    
    @include for-medium-screen() {
      @include flex-layout-horizontal(space-between, flex-start);
    }
  }
}
