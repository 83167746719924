@import "@/scss/default.scss";
@import "@/scss/mixin/form.scss";
@import "@/scss/mixin/layout.scss";
@import "@/scss/mixin/screen-size.scss";

$admin-panel-menu-height: 75px;

.admin-panel {
  padding: $gap-em;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  @include flex-vertical($gap-em);

  &__menu {
    &.menu_section-head-horizontal {
      .ant-menu {
        &-item {
          height: auto;
        }
      }
    }
  }
}

