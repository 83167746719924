.ils-input {
  width: 100%;

  &_text {

    &-area {
    }
  }

  &_password {
  }
  
  &_number {
  }

  &_search {
  }
}